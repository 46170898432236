import { Store } from '@ngrx/store';
import {Component, OnInit, EventEmitter, Output, Input, OnDestroy} from '@angular/core';
import { CdkDragEnter, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { CreateWorkbookOptionsComponent } from '../workbooks/workbook-cards.component';
import { MatDialog } from '@angular/material/dialog';
import { ViewWordsDialogComponent } from '../view-words-dialog/view-words-dialog.component';
import { Observable, Subscription, combineLatest, Subject } from 'rxjs';
import { _variable_images } from '../../../environments/environment';
import { DataService } from '../../services/data.service';
import { selectGetAllCourseWorkbook, selectGetMyLibraryWorkbooks, selectGetMyWhizzimoWorkbooks } from '../../store';
import { takeUntil } from 'rxjs/operators';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Router } from '@angular/router';
import { ListenerService } from '../../services/listener.service';

@Component({
  selector: 'app-drag-drop-cards',
  templateUrl: './drag-drop-cards.component.html',
  styleUrls: ['./drag-drop-cards.component.scss',
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss']
})
export class DragDropCardsComponent implements OnInit, OnDestroy {

  // @Input() data: any[] = [];
  //   // @ts-ignore

  // @Input() set libraries(libraries: any) {
  //   this.data = libraries;
  // }
  @Input() type: 'MyLibrary' | 'WhizzimoLibrary';
  data = [];
  @Input() hasSettings: boolean;
  @Input() hasNew: boolean;
  @Input() settings: any;
  @Input() workbookObservable: Observable<any>;
  @Input() searchMyLibraryObservable: Observable<any>;
  @Input() searchWhizzimoLibraryObservable: Observable<any>;
  @Output() removeFromCourseWorkbookEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() addWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() copyWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() editWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() addToCourseWorkbook: EventEmitter<any> = new EventEmitter();
  private unsubscribe$: Subject<void> = new Subject();

  subscriptions: Subscription[] = [];
  addCircleCurriculumImage: any;
  navigatePath: any;
  checkImage: any;
  isDark = false;
  myLibraryWorkbooks: any;
  whizzimoWorkbooks: any;
  courseWorkbooks: any;
  searchWord: string;
  selectedPlan: string;

  constructor(private dialog: MatDialog,
    private dataService: DataService,
    private store: Store,
    private router: Router,
    private listenerService: ListenerService) {
    this.navigatePath = localStorage.getItem('navigatePath');
    this.listenerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan: string) => {
      this.selectedPlan = plan;
    });
   }

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.addCircleCurriculumImage = path + 'add-circle-1.png';
      this.checkImage = path + 'check.png';
      if (path.split('/')[2] === 'theme-dark') {
        this.isDark = true;
      } else {
        this.isDark = false;
      }
    });
    // // this.addCircleCurriculumImage = _variable_images + 'add-circle-1.png';
    // if (this.workbookObservable) {
    //   const sub = this.workbookObservable.subscribe(workbook => {
    //     this.data.push(workbook);
    //     // this.library_tabname = localStorage.getItem('library_tabname');
    //   });
    //   this.subscriptions.push(sub);
    // }

    combineLatest([
      this.store.select(selectGetAllCourseWorkbook),
      this.store.select(selectGetMyLibraryWorkbooks)



    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([courseWorkbooks, myLibraryWorkbooks]) => {
        this.myLibraryWorkbooks = JSON.parse(JSON.stringify(myLibraryWorkbooks));
        if (this.type === 'MyLibrary') {
          const data = JSON.parse(JSON.stringify(myLibraryWorkbooks));
          this.courseWorkbooks = courseWorkbooks;

          this.courseWorkbooks?.forEach(courseWorkbook => {
            const index = data?.findIndex(d => courseWorkbook.workbookId === d._id);
            if (index > -1) {
              data[index].inCourse = true;
              data[index].courseWorkbookId = courseWorkbook._id;
              data[index].courseId = courseWorkbook.courseId;
            }
          });
          this.data = data.sort((a, b) => a.name.localeCompare(b.name));

        } else {
          this.processWhizzimoWorkbooks();
        }
    });

    if (this.type === 'MyLibrary') {
      this.searchMyLibraryObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(searchWord => {
          this.searchWord = searchWord;
          // this.data = JSON.parse(JSON.stringify(this.myLibraryWorkbooks)).filter((val) => {
          //   return val.name.toLowerCase().includes(searchWord.toLowerCase());
          // });

          // this.data.forEach(workbook => {
          //   const courseIndex = this.courseWorkbooks?.findIndex(cw => cw.workbookId === workbook._id);
          //   workbook.inCourse = courseIndex >= 0;
          // });
      });
    } else {
      this.store.select(selectGetMyWhizzimoWorkbooks)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(whizzimoWorkbooks => {
          this.whizzimoWorkbooks = JSON.parse(JSON.stringify(whizzimoWorkbooks));
          this.processWhizzimoWorkbooks();


      });
      this.searchWhizzimoLibraryObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(searchWord => {
          this.searchWord = searchWord;
          this.data = JSON.parse(JSON.stringify(this.whizzimoWorkbooks)).filter((val) => {
            return val.name.toLowerCase().includes(searchWord.toLowerCase());
          });
      });
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  removeFromCourseWorkbook(workbookId, courseWorkbookId, courseId) {
    this.removeFromCourseWorkbookEvent.emit({
      workbookId, courseWorkbookId, courseId
    });
  }

  addToCourse(workBook) {
    this.data = JSON.parse(JSON.stringify(this.data));
    if (this.type !== 'MyLibrary') {
      const existIndex = this.myLibraryWorkbooks.findIndex(item => item.name === workBook.name);
      if (existIndex > -1) {
        const dialogRef = this.dialog.open(MessageDialogComponent, {
          panelClass: 'custom-confirm-dialog',
          data: {
            title: 'Duplicate Workbook Title',
            content: 'You already have a workbook with this name in your library.  Please rename the workbook in your library before adding this workbook.'
          }
        });
        dialogRef.afterClosed().subscribe();
        return;
      }
    }

    this.data.map((item: any) => {
      if (item._id === workBook._id) {
        item['addedstatus'] = 'A';
      }
      setTimeout(() => {
        if (item._id === workBook._id) {
          if (this.router.url.includes('myLibrary')) {
          item['addedstatus'] = 'R';
          } else if (this.router.url.includes('whizzimoLibrary')) {
            item['addedstatus'] = '';
            }
        }
      }, 1500);
    });

    this.addToCourseWorkbook.emit(workBook);

  }

  edit(workBookId) {
    this.editWorkbook.emit(workBookId);
  }

  delete(workbookId, courseWorkbookId, courseId) {
    this.deleteWorkbook.emit({
      workbookId,
      courseWorkbookId,
      courseId
    });
  }

  copy(workBookId) {
    this.copyWorkbook.emit(workBookId);
  }

  viewWords(index): void {
    const dialogRef = this.dialog.open(ViewWordsDialogComponent, {
      width: '250px',
      panelClass: 'wordlist_modal',
      data: { words: this.data[index] }
    });
  }

  openDialog() {
    // NOTE: This prevents a user from creating a workbook in "My Library"
    if(this.selectedPlan === "Demo v2" || this.selectedPlan === "Hero v2") {
      return;
    }
    const dialogRef = this.dialog.open(CreateWorkbookOptionsComponent, {
      panelClass: 'add-wordbook-myLibrary-modalbox',
      width: '350px'
    });
  }

  entered(event: CdkDragEnter) {
    moveItemInArray(this.data, event.item.data, event.container.data);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  checkWorkbookExistInLibrary() {
    this.whizzimoWorkbooks?.forEach(item1 => {
      const existIndex = this.myLibraryWorkbooks.findIndex(item2 => item2.name === item1.name);
      item1.isExistInLibrary = existIndex > -1;
    });
  }

  processWhizzimoWorkbooks() {
    this.checkWorkbookExistInLibrary();
    this.data = this.whizzimoWorkbooks;

    if (this.searchWord) {
      this.data = JSON.parse(JSON.stringify(this.whizzimoWorkbooks)).filter((val) => {
        return val.name.toLowerCase().includes(this.searchWord.toLowerCase());
      });
    }
  }
}
