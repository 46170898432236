import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityHeaderService } from '../../services/activity-header.service';
import { selectCurrentTheme } from '../../store';

@Component({
  selector: 'app-question-mark',
  templateUrl: './html/support.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})


export class SupportComponent implements OnInit {

  @Input() Embedded: string;
  @Input() Title = '(Title)';

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {      
      data: {
        title: this.Title,
        link: this.Embedded

      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

@Component({
  selector: 'app-tutorial-button',
  templateUrl: './html/tutorial-button.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})
export class TutorialButtonComponent implements OnInit {
  activityTool: any;

  @Input() Embedded: string;
  @Input() Title = '(Title)';
  @Input() eraserStatus: any;
  @Input() drawStatus: any;
  @Input() textStatus: any;


  constructor(private dialog: MatDialog, public service: ActivityHeaderService) {}

  ngOnInit() {}

  openDialog(activityTool): void {
    console.log(this.Embedded);
    (window as any).Intercom('showArticle', `${this.Embedded}`);
    this.service.tool = '';
    this.service.activityTool = 'info';
    this.activityTool = activityTool;

    this.activityTool = '';
    if (this.drawStatus) {
      this.service.toggleTool('draw');
    } else if (this.eraserStatus) {
      this.service.toggleTool('eraser');
    } else if (this.textStatus) {
      this.service.toggleTool('text');
    }  
    // const dialogRef = this.dialog.open(TutorialDialogComponent, {
    //   panelClass: 'TutorialDialog_modal',
    //   data: {
    //     title: this.Title,
    //     link: this.Embedded
    //   },
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }
}

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './html/tutorial-dialog.html',
  styles: [
    '.mat-mdc-dialog-title.dark-mode { color: white }'
  ]
})

export class TutorialDialogComponent implements OnDestroy {
  isDarkMode: boolean;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store) {
    this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.isDarkMode = true;
        } else {
          this.isDarkMode = false;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
