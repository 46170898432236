import { Injectable } from '@angular/core';
import { TilesService } from './word-filter-tiles.service';
import { take } from 'rxjs/operators';
import {Observable, combineLatest, pipe} from 'rxjs';
import {ConfirmationDialogComponent} from '../components/confirmation-dialog/confirmation-dialog.component';
import {MessageDialogComponent} from '../components/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AccountService} from './account.service';
import {TermsOfServiceService} from './terms-of-service.service';
import {PrivacyPolicyService} from './privacy-policy-service'
import {UserService} from './user.service';
import { Util } from 'pdfjs-dist';

@Injectable()
export class UtilityService {

  constructor (
    private tilesService: TilesService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private termsOfServiceService: TermsOfServiceService,
    private userService: UserService,
    private privacyPolicyService: PrivacyPolicyService
  ) {

  }
  public static baseResolutionWidth = 1920;
  // public static yOffset = 640;
  public static yOffset = 240;
  public static courseWorkbookMenuWidth = '700px';
  public static courseWorkbookMenuHeight = '600px';
  public static defaultBlankTiles: string[] = [
    'tiles.blank.red',
    'tiles.blank.blue',
    'tiles.blank.lightyellow',
    'tiles.blank.green',
    'tiles.blank.orange',
    'tiles.blank.purple'
  ];

  public static termsOfService: any;

  public static privacyPolicy: any;

  activeLessonStepTab = 'Words';

  public tileBaseWidths: any = {
    "other": {1: 40, 2: 60, 3: 80, 4: 100},
    'tiles.bc.p': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.sh': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ch': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ck': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.wh': {
      '1': 49.625,
      '2': 60.8125,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.tch': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.eer': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.eir': {
      '1': 46.34375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ti': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.si': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.err': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.?i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ier': {
      '1': 46.34375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.1': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.zz': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.es': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ish': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ance': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.pref.suc': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.in': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dis': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.non': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.pref.un': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.uni': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.nov': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.suff.ite': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.astro': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.GreekCombiningForms.micro': {
      '1': 76.171875,
      '2': 98.09375,
      '3': 112.6875,
      '4': 134.609375
    },
    'tiles.roots.scend': {
      '1': 79.5,
      '2': 102.765625,
      '3': 118.25,
      '4': 141.515625
    },
    'tiles.GreekCombiningForms.mono': {
      '1': 76.140625,
      '2': 98.046875,
      '3': 112.640625,
      '4': 134.546875
    },
    'tiles.GreekCombiningForms.mega': {
      '1': 73.96875,
      '2': 95,
      '3': 109,
      '4': 130.03125
    },
    'tiles.roots.sequ': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.GreekCombiningForms.cardio': {
      '1': 81.703125,
      '2': 105.859375,
      '3': 121.9375,
      '4': 146.09375
    },
    'tiles.sight.x': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.q': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.b': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.air': {
      '1': 46.34375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.d': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.f': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.g': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.h': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.j': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.k': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.l': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.m': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.math.addition': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.math.subtraction': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.math.multiplication ': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    "tiles.math.division": {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.math.equals': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.math.fraction': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.0': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.1': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.2': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.3': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.4': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.5': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.6': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.7': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.8': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.num.9': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.schwa': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    "tiles.sym.'": {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ful': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ed': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.able': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.640625,
      '4': 104.75
    },
    'tiles.suff.ence': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.pref.suf': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.mis': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sub': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.re': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.pre': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.bi': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dec': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ition': {
      '1': 63.984375,
      '2': 80.96875,
      '3': 92.28125,
      '4': 109.265625
    },
    'tiles.GreekCombiningForms.auto': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.GreekCombiningForms.neo': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.roots.sult': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.GreekCombiningForms.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.poly': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.roots.theo': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.GreekCombiningForms.derm': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.sight.w': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.z': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.n': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.c': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.qu': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.r': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.t': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.v': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.w': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.x': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.z': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.blue': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ly': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ing': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ity': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.or': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sug': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.inter': {
      '1': 65.109375,
      '2': 82.546875,
      '3': 94.171875,
      '4': 111.609375
    },
    'tiles.pref.mid': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.over': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.pref.up': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.tri': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.deci': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.640625,
      '4': 104.75
    },
    'tiles.suff.itive': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.640625,
      '4': 104.75
    },
    'tiles.GreekCombiningForms.bio': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.ology': {
      '1': 75.046875,
      '2': 96.515625,
      '3': 110.796875,
      '4': 132.265625
    },
    'tiles.roots.sume': {
      '1': 73.96875,
      '2': 95,
      '3': 109,
      '4': 130.03125
    },
    'tiles.GreekCombiningForms.tri': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.hemi': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.roots.serval': {
      '1': 79.515625,
      '2': 102.796875,
      '3': 118.296875,
      '4': 141.578125
    },
    'tiles.GreekCombiningForms.ectomy': {
      '1': 91.65625,
      '2': 119.84375,
      '3': 138.609375,
      '4': 166.796875
    },
    'tiles.sight.v': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.all': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.oll': {
      '1': 45.203125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.arr': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ay': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ee': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ow': {
      '1': 49.625,
      '2': 60.8125,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oe': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ew': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ue': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ai': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oa': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ie': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.igh': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.blank.3': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.less': {
      '1': 60.71875,
      '2': 76.390625,
      '3': 86.8125,
      '4': 102.484375
    },
    'tiles.suff.er': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.an': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sup': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.trans': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.pref.im': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.para': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.pref.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.quad': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.pref.cent': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.suff.ive': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.chron': {
      '1': 77.265625,
      '2': 99.625,
      '3': 114.515625,
      '4': 136.875
    },
    'tiles.GreekCombiningForms.paleo': {
      '1': 73.953125,
      '2': 94.984375,
      '3': 108.984375,
      '4': 130
    },
    'tiles.roots.struc': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.GreekCombiningForms.tetra': {
      '1': 65.125,
      '2': 82.5625,
      '3': 94.1875,
      '4': 111.640625
    },
    'tiles.GreekCombiningForms.iso': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.sess': {
      '1': 66.25,
      '2': 84.15625,
      '3': 96.09375,
      '4': 114
    },
    'tiles.GreekCombiningForms.emia': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.sight.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ang': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.gs.ank': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.old': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ild': {
      '1': 45.203125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oi': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oy': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ey': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.au': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.aw': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oo': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ou': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ea': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ei': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.augh': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.blank.gray': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ness': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.suff.est': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ous': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.suff.ette': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.21875,
      '4': 100
    },
    'tiles.pref.super': {
      '1': 76.171875,
      '2': 98.09375,
      '3': 112.6875,
      '4': 134.609375
    },
    'tiles.pref.multi': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.pref.semi': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.pref.anti': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.micro': {
      '1': 76.171875,
      '2': 98.09375,
      '3': 112.6875,
      '4': 134.609375
    },
    'tiles.pref.quin': {
      '1': 63.984375,
      '2': 80.96875,
      '3': 92.28125,
      '4': 109.265625
    },
    'tiles.pref.centi': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.suff.nomy': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.GreekCombiningForms.geo': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.GreekCombiningForms.phone': {
      '1': 81.671875,
      '2': 105.8125,
      '3': 121.890625,
      '4': 146.03125
    },
    'tiles.roots.voke': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.GreekCombiningForms.penta': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.GreekCombiningForms.gon': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.roots.urb': {
      '1': 54.046875,
      '2': 67.015625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.gastro': {
      '1': 82.796875,
      '2': 107.390625,
      '3': 123.78125,
      '4': 148.375
    },
    'tiles.sight.t': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ing': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ink': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.olt': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ind': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.vt.eu': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.wor': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.bv.é': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.eau': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.irr': {
      '1': 43.015625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.oar': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.oor': {
      '1': 54.046875,
      '2': 67.015625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ore': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.orr': {
      '1': 49.640625,
      '2': 60.828125,
      '3': 80,
      '4': 100
    },
    'tiles.vt.eigh': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.blank.green': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ment': {
      '1': 69.53125,
      '2': 88.765625,
      '3': 101.5625,
      '4': 120.796875
    },
    'tiles.suff.ist': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ize': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ess': {
      '1': 55.1875,
      '2': 68.625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sur': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.macro': {
      '1': 81.703125,
      '2': 105.875,
      '3': 121.953125,
      '4': 146.125
    },
    'tiles.pref.co': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.auto': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.pref.self-': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.pref.sex': {
      '1': 55.1875,
      '2': 68.625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.mill': {
      '1': 56.265625,
      '2': 70.140625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ology': {
      '1': 75.046875,
      '2': 96.515625,
      '3': 110.796875,
      '4': 132.265625
    },
    'tiles.GreekCombiningForms.dynamics': {
      '1': 113.765625,
      '2': 150.90625,
      '3': 175.625,
      '4': 212.765625
    },
    'tiles.GreekCombiningForms.photo': {
      '1': 77.234375,
      '2': 99.578125,
      '3': 114.453125,
      '4': 136.8125
    },
    'tiles.roots.stant': {
      '1': 69.53125,
      '2': 88.765625,
      '3': 101.5625,
      '4': 120.796875
    },
    'tiles.GreekCombiningForms.hexa': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.GreekCombiningForms.lateral': {
      '1': 80.609375,
      '2': 104.328125,
      '3': 120.125,
      '4': 143.84375
    },
    'tiles.roots.vent': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.GreekCombiningForms.hema': {
      '1': 73.96875,
      '2': 95,
      '3': 109,
      '4': 130.03125
    },
    'tiles.sight.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ong': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.gs.onk': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.gs.ost': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ure': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.urr': {
      '1': 49.640625,
      '2': 60.828125,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.war': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.yr': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.dge': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.cdt.ph': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.th': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.bb': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.cc': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.dd': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ff': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.nn': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ss': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ic': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sus': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.tele': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.counter': {
      '1': 94.9375,
      '2': 124.46875,
      '3': 144.125,
      '4': 173.640625
    },
    'tiles.pref.bio': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.geo': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.de': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sept': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.pref.milli': {
      '1': 61.796875,
      '2': 77.90625,
      '3': 88.625,
      '4': 104.734375
    },
    'tiles.suff.ture': {
      '1': 59.578125,
      '2': 74.796875,
      '3': 84.921875,
      '4': 100.125
    },
    'tiles.GreekCombiningForms.electro': {
      '1': 87.234375,
      '2': 113.625,
      '3': 131.203125,
      '4': 157.609375
    },
    'tiles.GreekCombiningForms.scope': {
      '1': 79.5,
      '2': 102.765625,
      '3': 118.25,
      '4': 141.515625
    },
    'tiles.roots.liberat': {
      '1': 81.703125,
      '2': 105.859375,
      '3': 121.9375,
      '4': 146.09375
    },
    'tiles.GreekCombiningForms.hepta': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.GreekCombiningForms.peri': {
      '1': 58.484375,
      '2': 73.25,
      '3': 83.078125,
      '4': 100
    },
    'tiles.roots.sign': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.GreekCombiningForms.itis': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.sight.r': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ung': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.gs.unk': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.gs.alk': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.sure': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.cdt.ci': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ar': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.er': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.or': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ur': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ear': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.our': {
      '1': 54.046875,
      '2': 67.015625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.?r': {
      '1': 44.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ll': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.pp': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.tt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.en': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ible': {
      '1': 56.265625,
      '2': 70.140625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.under': {
      '1': 77.265625,
      '2': 99.625,
      '3': 114.515625,
      '4': 136.875
    },
    'tiles.suff.ant': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.equi': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.suff.on': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ry': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ship': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.pref.oct': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.th': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ty': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.graph': {
      '1': 77.265625,
      '2': 99.625,
      '3': 114.515625,
      '4': 136.875
    },
    'tiles.GreekCombiningForms.sphere': {
      '1': 87.234375,
      '2': 113.625,
      '3': 131.203125,
      '4': 157.609375
    },
    'tiles.roots.lect': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.octo': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.GreekCombiningForms.cycle': {
      '1': 71.78125,
      '2': 91.921875,
      '3': 105.34375,
      '4': 125.484375
    },
    'tiles.roots.vers': {
      '1': 62.9375,
      '2': 79.5,
      '3': 90.53125,
      '4': 107.09375
    },
    'tiles.GreekCombiningForms.neuro': {
      '1': 77.265625,
      '2': 99.625,
      '3': 114.515625,
      '4': 136.875
    },
    'tiles.sight.q': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.sion': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.gs.tion': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.gs.ive': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ture': {
      '1': 59.578125,
      '2': 74.796875,
      '3': 84.921875,
      '4': 100.125
    },
    'tiles.cdt.dg': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ia': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.iew': {
      '1': 54.0625,
      '2': 67.046875,
      '3': 80,
      '4': 100
    },
    'tiles.vt.io': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.iou': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ui': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.uy': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.gg': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.kk': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.mm': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.21875,
      '4': 100
    },
    'tiles.bl.rr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ww': {
      '1': 52.953125,
      '2': 65.46875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.age': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ain': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ancy': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.suff.ar': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ard': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ory': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.sion': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.suff.sure': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.suff.tion': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.suff.ual': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.hydro': {
      '1': 77.265625,
      '2': 99.625,
      '3': 114.515625,
      '4': 136.875
    },
    'tiles.GreekCombiningForms.tele': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.liber': {
      '1': 64.015625,
      '2': 81.015625,
      '3': 92.328125,
      '4': 109.328125
    },
    'tiles.GreekCombiningForms.enne': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.GreekCombiningForms.dia': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.vert': {
      '1': 58.5,
      '2': 73.265625,
      '3': 83.09375,
      '4': 100
    },
    'tiles.GreekCombiningForms.oma': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.sight.p': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ice': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.age': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ite': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ine': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.am': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.slc.wr': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.rh': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.que': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.acs.qué': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.slc.gh': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.olk': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.slc.bt': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gu': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.lf': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.mn': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.in': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.con': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ob': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sub': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ad': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ary': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.roots.act': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.empt': {
      '1': 69.53125,
      '2': 88.765625,
      '3': 101.5625,
      '4': 120.796875
    },
    'tiles.roots.low': {
      '1': 55.15625,
      '2': 68.5625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pose': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.roots.sist': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ure': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.kinetic': {
      '1': 85.015625,
      '2': 110.515625,
      '3': 127.5,
      '4': 153
    },
    'tiles.GreekCombiningForms.therm': {
      '1': 77.265625,
      '2': 99.640625,
      '3': 114.53125,
      '4': 136.890625
    },
    'tiles.roots.equi': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.GreekCombiningForms.deca': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.GreekCombiningForms.gram': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.roots.vise': {
      '1': 60.71875,
      '2': 76.390625,
      '3': 86.8125,
      '4': 102.484375
    },
    'tiles.GreekCombiningForms.osteo': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.sight.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ace': {
      '1': 55.1875,
      '2': 68.625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.alt': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ate': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.gs.an': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ile': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gn': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gue': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.slc.mb': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.slc.ps': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.kn': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.aigh': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.avs.ough': {
      '1': 70.609375,
      '2': 90.28125,
      '3': 103.375,
      '4': 123.046875
    },
    'tiles.avs.qué': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.slc.sc': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.pn': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.im': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.com': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.LatinChameleonPrefixes.oc': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.suc': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ac': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ate': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.ceed': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.roots.fect': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.21875,
      '4': 100
    },
    'tiles.roots.mit': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.press': {
      '1': 75.078125,
      '2': 96.5625,
      '3': 110.875,
      '4': 132.34375
    },
    'tiles.roots.solve': {
      '1': 72.875,
      '2': 93.453125,
      '3': 107.15625,
      '4': 127.75
    },
    'tiles.suff.ward': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.GreekCombiningForms.macro': {
      '1': 81.703125,
      '2': 105.875,
      '3': 121.953125,
      '4': 146.125
    },
    'tiles.roots.cid': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.equ': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.GreekCombiningForms.hecto': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.roots.cis': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.vict': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.path': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.sight.n': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.stle': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.avs.tu': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.wa': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.ng': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.nk': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.lightyellow': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.orange': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.peach': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.pink': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.purple': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.red': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.yellow': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.lightgreen': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.cream': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.clear': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.il': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.col': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.of': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.suf': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.af': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ation': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.roots.cel': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.fer': {
      '1': 47.4375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.nul': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pulse': {
      '1': 73.953125,
      '2': 94.984375,
      '3': 108.984375,
      '4': 130
    },
    'tiles.roots.struct': {
      '1': 77.265625,
      '2': 99.640625,
      '3': 114.53125,
      '4': 136.890625
    },
    'tiles.suff.xion': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.GreekCombiningForms.meter': {
      '1': 76.1875,
      '2': 98.109375,
      '3': 112.703125,
      '4': 134.625
    },
    'tiles.roots.civ': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.judg': {
      '1': 63.984375,
      '2': 80.96875,
      '3': 92.28125,
      '4': 109.265625
    },
    'tiles.GreekCombiningForms.kilo': {
      '1': 56.265625,
      '2': 70.140625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.cit': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.spect': {
      '1': 73.96875,
      '2': 95,
      '3': 109,
      '4': 130.03125
    },
    'tiles.GreekCombiningForms.psycho': {
      '1': 91.640625,
      '2': 119.828125,
      '3': 138.59375,
      '4': 166.765625
    },
    'tiles.sight.m': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ct': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lb': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lch': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ld': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lf': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lk': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lm': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lp': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lth': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.mp': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.mpt': {
      '1': 58.46875,
      '2': 73.21875,
      '3': 83.046875,
      '4': 100
    },
    'tiles.endblend.nch': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.endblend.nd': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.cor': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.op': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sug': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ag': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ative': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.roots.cept': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.roots.form': {
      '1': 66.203125,
      '2': 84.09375,
      '3': 96,
      '4': 113.890625
    },
    'tiles.roots.pel': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.quaint': {
      '1': 81.671875,
      '2': 105.8125,
      '3': 121.890625,
      '4': 146.03125
    },
    'tiles.roots.tract': {
      '1': 65.125,
      '2': 82.5625,
      '3': 94.1875,
      '4': 111.640625
    },
    'tiles.roots.annu': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.roots.poli': {
      '1': 57.359375,
      '2': 71.671875,
      '3': 81.1875,
      '4': 100
    },
    'tiles.roots.civil': {
      '1': 60.703125,
      '2': 76.375,
      '3': 86.796875,
      '4': 102.46875
    },
    'tiles.roots.egal': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.640625,
      '4': 104.75
    },
    'tiles.roots.clude': {
      '1': 73.953125,
      '2': 94.984375,
      '3': 108.984375,
      '4': 130
    },
    'tiles.roots.cracy': {
      '1': 74,
      '2': 95.03125,
      '3': 109.046875,
      '4': 130.09375
    },
    'tiles.roots.spire': {
      '1': 69.546875,
      '2': 88.78125,
      '3': 101.59375,
      '4': 120.84375
    },
    'tiles.GreekCombiningForms.therapy': {
      '1': 93.859375,
      '2': 122.9375,
      '3': 142.296875,
      '4': 171.375
    },
    'tiles.sight.l': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nk': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nt': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.pt': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sc': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sk': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sm': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sp': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.xt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.bl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.br': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.chl': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.chr': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.cl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.cr': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.dr': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.dw': {
      '1': 49.625,
      '2': 60.8125,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sup': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.cracy': {
      '1': 74,
      '2': 95.03125,
      '3': 109.046875,
      '4': 130.09375
    },
    'tiles.roots.cess': {
      '1': 66.25,
      '2': 84.15625,
      '3': 96.09375,
      '4': 114
    },
    'tiles.roots.fuse': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.roots.pend': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.roots.quit': {
      '1': 58.453125,
      '2': 73.203125,
      '3': 83.03125,
      '4': 100
    },
    'tiles.roots.verse': {
      '1': 74,
      '2': 95.03125,
      '3': 109.046875,
      '4': 130.09375
    },
    'tiles.roots.de': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.demo': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.GreekCombiningForms.crat': {
      '1': 58.5,
      '2': 73.265625,
      '3': 83.09375,
      '4': 100
    },
    'tiles.roots.jucic': {
      '1': 67.328125,
      '2': 85.671875,
      '3': 97.890625,
      '4': 116.234375
    },
    'tiles.GreekCombiningForms.cracy': {
      '1': 74,
      '2': 95.03125,
      '3': 109.046875,
      '4': 130.09375
    },
    'tiles.roots.crat': {
      '1': 58.5,
      '2': 73.265625,
      '3': 83.09375,
      '4': 100
    },
    'tiles.roots.the': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    "tiles.sight.'": {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.k': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sm': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.skr': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sk': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.shr': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.scr': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.scl': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sch': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sc': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.pr': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.pl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.phr': {
      '1': 54.046875,
      '2': 67.015625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.phl': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gw': {
      '1': 49.625,
      '2': 60.8125,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gr': {
      '1': 41.890625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.fr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.fl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sus': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.an': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.cy': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.count': {
      '1': 76.140625,
      '2': 98.046875,
      '3': 112.640625,
      '4': 134.546875
    },
    'tiles.roots.gest': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.roots.pense': {
      '1': 79.5,
      '2': 102.765625,
      '3': 118.25,
      '4': 141.515625
    },
    'tiles.roots.range': {
      '1': 76.171875,
      '2': 98.09375,
      '3': 112.6875,
      '4': 134.609375
    },
    'tiles.suff.ics': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.dem': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.GreekCombiningForms.phil': {
      '1': 57.359375,
      '2': 71.671875,
      '3': 81.1875,
      '4': 100
    },
    'tiles.GreekCombiningForms.anthro': {
      '1': 83.875,
      '2': 108.921875,
      '3': 125.59375,
      '4': 150.640625
    },
    'tiles.roots.popul': {
      '1': 76.125,
      '2': 98.03125,
      '3': 112.625,
      '4': 134.53125
    },
    'tiles.GreekCombiningForms.ethno': {
      '1': 76.140625,
      '2': 98.046875,
      '3': 112.640625,
      '4': 134.546875
    },
    'tiles.GreekCombiningForms.soph': {
      '1': 69.515625,
      '2': 88.75,
      '3': 101.546875,
      '4': 120.78125
    },
    'tiles.GreekCombiningForms.astr': {
      '1': 58.5,
      '2': 73.265625,
      '3': 83.09375,
      '4': 100
    },
    'tiles.sight.-': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.j': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sn': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sp': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sph': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.initblend.spl': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.spr': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.squ': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.initblend.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.str': {
      '1': 47.4375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sw': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.thr': {
      '1': 48.515625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.thw': {
      '1': 56.25,
      '2': 70.109375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.tr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.tw': {
      '1': 44.109375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.ble': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.cle': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ex': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.dis': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.dle': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.fle': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ap': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.dict': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.roots.cur': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.gress': {
      '1': 75.078125,
      '2': 96.5625,
      '3': 110.875,
      '4': 132.34375
    },
    'tiles.roots.ply': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.rect': {
      '1': 58.5,
      '2': 73.265625,
      '3': 83.09375,
      '4': 100
    },
    'tiles.suff.ily': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.arch': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.GreekCombiningForms.polis': {
      '1': 68.421875,
      '2': 87.203125,
      '3': 99.71875,
      '4': 118.5
    },
    'tiles.roots.polis': {
      '1': 68.421875,
      '2': 87.203125,
      '3': 99.71875,
      '4': 118.5
    },
    'tiles.GreekCombiningForms.theo': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.roots.cred': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.GreekCombiningForms.hydr': {
      '1': 65.109375,
      '2': 82.546875,
      '3': 94.171875,
      '4': 111.609375
    },
    'tiles.sight.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.arrowleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.arrowright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.bracketleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.bracketright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.colon': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.comma': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.exclamation': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.plus': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.question': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.zle': {
      '1': 48.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.tle': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.qule': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.consle.ple': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.nle': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.mle': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ef': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.dif': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.kle': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.consle.gle': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ar': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.dom': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.roots.dict': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.roots.ject': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.point': {
      '1': 70.609375,
      '2': 90.28125,
      '3': 103.375,
      '4': 123.046875
    },
    'tiles.roots.rupt': {
      '1': 60.671875,
      '2': 76.3125,
      '3': 86.734375,
      '4': 102.390625
    },
    'tiles.suff.ine': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.arch': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.roots.grad': {
      '1': 65.109375,
      '2': 82.546875,
      '3': 94.171875,
      '4': 111.609375
    },
    'tiles.roots.fid': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.fidel': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.roots.flect': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.roots.flict': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.GreekCombiningForms.cardi': {
      '1': 69.546875,
      '2': 88.78125,
      '3': 101.59375,
      '4': 120.84375
    },
    'tiles.sight.b': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.h': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym._': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.-': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.apostrophe': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.arrowleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.arrowright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.bracketleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.bracketright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.colon': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.comma': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.dash': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.exclamation': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.period': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.plus': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.question': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.schwa': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.at': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.as': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ee': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.duct': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.roots.just': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.roots.port': {
      '1': 60.671875,
      '2': 76.3125,
      '3': 86.734375,
      '4': 102.390625
    },
    'tiles.roots.sent': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.suff.ion': {
      '1': 51.828125,
      '2': 63.90625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.demo': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.roots.dic': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pact': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.roots.fess': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.65625,
      '4': 104.765625
    },
    'tiles.roots.scribe': {
      '1': 80.609375,
      '2': 104.328125,
      '3': 120.125,
      '4': 143.84375
    },
    'tiles.roots.tent': {
      '1': 58.46875,
      '2': 73.21875,
      '3': 83.046875,
      '4': 100
    },
    'tiles.GreekCombiningForms.gastr': {
      '1': 70.640625,
      '2': 90.328125,
      '3': 103.4375,
      '4': 123.125
    },
    'tiles.sight.c': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.g': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ab': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ac': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.act': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ad': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.af': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ag': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.an': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ap': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ar': {
      '1': 40.8125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.as': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.at': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.be': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.circum': {
      '1': 87.234375,
      '2': 113.625,
      '3': 131.203125,
      '4': 157.609375
    },
    'tiles.pref.il': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.oc': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.of': {
      '1': 40.78125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.per': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.se': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ency': {
      '1': 67.34375,
      '2': 85.6875,
      '3': 97.90625,
      '4': 116.25
    },
    'tiles.suff.ern': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ese': {
      '1': 55.1875,
      '2': 68.625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.fic': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ian': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ious': {
      '1': 62.890625,
      '2': 79.4375,
      '3': 90.46875,
      '4': 107.015625
    },
    'tiles.roots.derv': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.roots.divin': {
      '1': 68.421875,
      '2': 87.203125,
      '3': 99.71875,
      '4': 118.5
    },
    'tiles.roots.quire': {
      '1': 70.640625,
      '2': 90.3125,
      '3': 103.421875,
      '4': 123.09375
    },
    'tiles.roots.pute': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.roots.sect': {
      '1': 61.8125,
      '2': 77.921875,
      '3': 88.65625,
      '4': 104.765625
    },
    'tiles.roots.tend': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.GreekCombiningForms.poli': {
      '1': 57.359375,
      '2': 71.671875,
      '3': 81.1875,
      '4': 100
    },
    'tiles.sight.d': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.f': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.col': {
      '1': 50.75,
      '2': 62.375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.com': {
      '1': 62.90625,
      '2': 79.453125,
      '3': 90.484375,
      '4': 107.03125
    },
    'tiles.pref.con': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.203125,
      '4': 100
    },
    'tiles.pref.contra': {
      '1': 82.796875,
      '2': 107.390625,
      '3': 123.78125,
      '4': 148.375
    },
    'tiles.pref.cor': {
      '1': 52.953125,
      '2': 65.484375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dif': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ef': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.em': {
      '1': 50.75,
      '2': 62.390625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.en': {
      '1': 45.21875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ex': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.extra': {
      '1': 69.5625,
      '2': 88.796875,
      '3': 101.609375,
      '4': 120.859375
    },
    'tiles.pref.fore': {
      '1': 59.578125,
      '2': 74.796875,
      '3': 84.921875,
      '4': 100.125
    },
    'tiles.pref.intro': {
      '1': 66.203125,
      '2': 84.078125,
      '3': 95.984375,
      '4': 113.875
    },
    'tiles.pref.ob': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.octo': {
      '1': 64,
      '2': 80.984375,
      '3': 92.296875,
      '4': 109.296875
    },
    'tiles.pref.op': {
      '1': 46.3125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.pro': {
      '1': 54.046875,
      '2': 67.015625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.self': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ent': {
      '1': 51.84375,
      '2': 63.921875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ery': {
      '1': 51.875,
      '2': 63.953125,
      '3': 80,
      '4': 100
    },
    'tiles.suff.et': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ial': {
      '1': 44.125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ice': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ism': {
      '1': 56.28125,
      '2': 70.15625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.archy': {
      '1': 75.078125,
      '2': 96.5625,
      '3': 110.875,
      '4': 132.34375
    },
    'tiles.roots.jec': {
      '1': 49.65625,
      '2': 60.84375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.mand': {
      '1': 75.046875,
      '2': 96.53125,
      '3': 110.8125,
      '4': 132.28125
    },
    'tiles.roots.pres': {
      '1': 64.015625,
      '2': 81.03125,
      '3': 92.34375,
      '4': 109.359375
    },
    'tiles.roots.tact': {
      '1': 57.375,
      '2': 71.6875,
      '3': 81.21875,
      '4': 100
    },
    'tiles.roots.secut': {
      '1': 73.96875,
      '2': 95,
      '3': 109,
      '4': 130.03125
    },
    'tiles.GreekCombiningForms.psych': {
      '1': 79.5,
      '2': 102.765625,
      '3': 118.25,
      '4': 141.515625
    },
    'tiles.GreekCombiningForms.scop': {
      '1': 68.4375,
      '2': 87.21875,
      '3': 99.734375,
      '4': 118.515625
    },
    'tiles.sight.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    }
  };

  public tileHDWidths: any = {
    "other": {1: 40, 2: 60, 3: 80, 4: 100},
    'tiles.bc.p': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.sh': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ch': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ck': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.wh': {
      '1': 47.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.tch': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.eer': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.eir': {
      '1': 44.578125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ti': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.si': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.err': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.?i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ier': {
      '1': 44.578125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.1': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.zz': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.es': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ish': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ance': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.pref.suc': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.in': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dis': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.non': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.pref.un': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.uni': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.nov': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ite': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.astro': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.GreekCombiningForms.micro': {
      '1': 72.25,
      '2': 92.59375,
      '3': 106.125,
      '4': 126.46875
    },
    'tiles.roots.scend': {
      '1': 75.328125,
      '2': 96.921875,
      '3': 111.28125,
      '4': 132.875
    },
    'tiles.GreekCombiningForms.mono': {
      '1': 72.21875,
      '2': 92.546875,
      '3': 106.078125,
      '4': 126.40625
    },
    'tiles.GreekCombiningForms.mega': {
      '1': 70.203125,
      '2': 89.71875,
      '3': 102.703125,
      '4': 122.21875
    },
    'tiles.roots.sequ': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.GreekCombiningForms.cardio': {
      '1': 77.375,
      '2': 99.796875,
      '3': 114.703125,
      '4': 137.125
    },
    'tiles.sight.x': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.q': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.b': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.air': {
      '1': 44.578125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.d': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.f': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.g': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.h': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.j': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.k': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.l': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.m': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.schwa': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    "tiles.sym.'": {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ful': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ed': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.able': {
      '1': 58.921875,
      '2': 73.875,
      '3': 83.8125,
      '4': 100
    },
    'tiles.suff.ence': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.pref.suf': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.mis': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sub': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.re': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.pre': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.bi': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dec': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ition': {
      '1': 60.9375,
      '2': 76.703125,
      '3': 87.203125,
      '4': 102.953125
    },
    'tiles.GreekCombiningForms.auto': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.GreekCombiningForms.neo': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.sult': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.poly': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.roots.theo': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.GreekCombiningForms.derm': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.sight.w': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.z': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.n': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.c': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.qu': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.r': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.t': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.v': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.w': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.x': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bc.z': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.blue': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ly': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ing': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ity': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.or': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sug': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.inter': {
      '1': 61.984375,
      '2': 78.171875,
      '3': 88.953125,
      '4': 105.140625
    },
    'tiles.pref.mid': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.over': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.pref.up': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.tri': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.deci': {
      '1': 58.921875,
      '2': 73.875,
      '3': 83.8125,
      '4': 100
    },
    'tiles.suff.itive': {
      '1': 58.921875,
      '2': 73.875,
      '3': 83.8125,
      '4': 100
    },
    'tiles.GreekCombiningForms.bio': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.ology': {
      '1': 71.203125,
      '2': 91.125,
      '3': 104.375,
      '4': 124.296875
    },
    'tiles.roots.sume': {
      '1': 70.203125,
      '2': 89.71875,
      '3': 102.703125,
      '4': 122.21875
    },
    'tiles.GreekCombiningForms.tri': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.hemi': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.roots.serval': {
      '1': 75.359375,
      '2': 96.953125,
      '3': 111.328125,
      '4': 132.921875
    },
    'tiles.GreekCombiningForms.ectomy': {
      '1': 86.609375,
      '2': 112.765625,
      '3': 130.171875,
      '4': 156.328125
    },
    'tiles.sight.v': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.all': {
      '1': 42.515625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bv.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.oll': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.arr': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ay': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ee': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ow': {
      '1': 47.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oe': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ew': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ue': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ai': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oa': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ie': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.igh': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.blank.3': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.less': {
      '1': 57.921875,
      '2': 72.453125,
      '3': 82.125,
      '4': 100
    },
    'tiles.suff.er': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.an': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sup': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.trans': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.pref.im': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.para': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.pref.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.quad': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.pref.cent': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.suff.ive': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.chron': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.8125,
      '4': 128.5625
    },
    'tiles.GreekCombiningForms.paleo': {
      '1': 70.203125,
      '2': 89.703125,
      '3': 102.6875,
      '4': 122.203125
    },
    'tiles.roots.struc': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.GreekCombiningForms.tetra': {
      '1': 62,
      '2': 78.1875,
      '3': 88.96875,
      '4': 105.15625
    },
    'tiles.GreekCombiningForms.iso': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.sess': {
      '1': 63.046875,
      '2': 79.671875,
      '3': 90.71875,
      '4': 107.34375
    },
    'tiles.GreekCombiningForms.emia': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.sight.u': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ang': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ank': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.gs.old': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ild': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oi': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oy': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ey': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.au': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.aw': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.oo': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ou': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ea': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ei': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.augh': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.blank.gray': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ness': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.suff.est': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ous': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ette': {
      '1': 54.8125,
      '2': 68.109375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.super': {
      '1': 72.25,
      '2': 92.59375,
      '3': 106.125,
      '4': 126.46875
    },
    'tiles.pref.multi': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.pref.semi': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.pref.anti': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.micro': {
      '1': 72.25,
      '2': 92.59375,
      '3': 106.125,
      '4': 126.46875
    },
    'tiles.pref.quin': {
      '1': 60.9375,
      '2': 76.703125,
      '3': 87.203125,
      '4': 102.953125
    },
    'tiles.pref.centi': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.suff.nomy': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.GreekCombiningForms.geo': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.phone': {
      '1': 77.34375,
      '2': 99.75,
      '3': 114.65625,
      '4': 137.0625
    },
    'tiles.roots.voke': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.GreekCombiningForms.penta': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.GreekCombiningForms.gon': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.roots.urb': {
      '1': 51.734375,
      '2': 63.765625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.gastro': {
      '1': 78.390625,
      '2': 101.21875,
      '3': 116.40625,
      '4': 139.234375
    },
    'tiles.sight.t': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ing': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ink': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.olt': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ind': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.vt.eu': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.wor': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.bv.é': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.eau': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.irr': {
      '1': 41.5,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.oar': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.oor': {
      '1': 51.734375,
      '2': 63.765625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ore': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.orr': {
      '1': 47.640625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.eigh': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.blank.green': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ment': {
      '1': 66.09375,
      '2': 83.9375,
      '3': 95.8125,
      '4': 113.65625
    },
    'tiles.suff.ist': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ize': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ess': {
      '1': 52.796875,
      '2': 65.25,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sur': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.macro': {
      '1': 77.390625,
      '2': 99.8125,
      '3': 114.71875,
      '4': 137.140625
    },
    'tiles.pref.co': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.auto': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.pref.self-': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.pref.sex': {
      '1': 52.796875,
      '2': 65.25,
      '3': 80,
      '4': 100
    },
    'tiles.pref.mill': {
      '1': 53.796875,
      '2': 66.65625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ology': {
      '1': 71.203125,
      '2': 91.125,
      '3': 104.375,
      '4': 124.296875
    },
    'tiles.GreekCombiningForms.dynamics': {
      '1': 107.125,
      '2': 141.578125,
      '3': 164.515625,
      '4': 198.96875
    },
    'tiles.GreekCombiningForms.photo': {
      '1': 73.234375,
      '2': 93.96875,
      '3': 107.765625,
      '4': 128.515625
    },
    'tiles.roots.stant': {
      '1': 66.09375,
      '2': 83.9375,
      '3': 95.8125,
      '4': 113.65625
    },
    'tiles.GreekCombiningForms.hexa': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.GreekCombiningForms.lateral': {
      '1': 76.359375,
      '2': 98.375,
      '3': 113.015625,
      '4': 135.03125
    },
    'tiles.roots.vent': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.GreekCombiningForms.hema': {
      '1': 70.203125,
      '2': 89.71875,
      '3': 102.703125,
      '4': 122.21875
    },
    'tiles.sight.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ong': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.gs.onk': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ost': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ure': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.urr': {
      '1': 47.640625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.war': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.yr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.dge': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.ph': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.cdt.th': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.bb': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.cc': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.dd': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ff': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.nn': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ss': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.y': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ic': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sus': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.tele': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.counter': {
      '1': 89.65625,
      '2': 117.0625,
      '3': 135.28125,
      '4': 162.671875
    },
    'tiles.pref.bio': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.pref.geo': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.de': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.sept': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.pref.milli': {
      '1': 58.921875,
      '2': 73.859375,
      '3': 83.796875,
      '4': 100
    },
    'tiles.suff.ture': {
      '1': 56.859375,
      '2': 70.96875,
      '3': 80.359375,
      '4': 100
    },
    'tiles.GreekCombiningForms.electro': {
      '1': 82.515625,
      '2': 107,
      '3': 123.3125,
      '4': 147.796875
    },
    'tiles.GreekCombiningForms.scope': {
      '1': 75.328125,
      '2': 96.921875,
      '3': 111.28125,
      '4': 132.875
    },
    'tiles.roots.liberat': {
      '1': 77.375,
      '2': 99.796875,
      '3': 114.703125,
      '4': 137.125
    },
    'tiles.GreekCombiningForms.hepta': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.GreekCombiningForms.peri': {
      '1': 55.84375,
      '2': 69.546875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.sign': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.GreekCombiningForms.itis': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.r': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ung': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.gs.unk': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.gs.alk': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.sure': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.cdt.ci': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ar': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.er': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.or': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ur': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.ear': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.our': {
      '1': 51.734375,
      '2': 63.765625,
      '3': 80,
      '4': 100
    },
    'tiles.rcv.?r': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ll': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.pp': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.tt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.en': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ible': {
      '1': 53.796875,
      '2': 66.65625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.under': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.8125,
      '4': 128.5625
    },
    'tiles.suff.ant': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.equi': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.suff.on': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ry': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ship': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.pref.oct': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.th': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ty': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.graph': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.8125,
      '4': 128.5625
    },
    'tiles.GreekCombiningForms.sphere': {
      '1': 82.515625,
      '2': 107,
      '3': 123.3125,
      '4': 147.796875
    },
    'tiles.roots.lect': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.octo': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.GreekCombiningForms.cycle': {
      '1': 68.171875,
      '2': 86.875,
      '3': 99.3125,
      '4': 118
    },
    'tiles.roots.vers': {
      '1': 59.96875,
      '2': 75.34375,
      '3': 85.5625,
      '4': 100.9375
    },
    'tiles.GreekCombiningForms.neuro': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.8125,
      '4': 128.5625
    },
    'tiles.sight.q': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.sion': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.gs.tion': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ive': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ture': {
      '1': 56.859375,
      '2': 70.96875,
      '3': 80.359375,
      '4': 100
    },
    'tiles.cdt.dg': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ia': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.iew': {
      '1': 51.75,
      '2': 63.78125,
      '3': 80,
      '4': 100
    },
    'tiles.vt.io': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.iou': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.vt.ui': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.vt.uy': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.gg': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.kk': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.mm': {
      '1': 54.8125,
      '2': 68.109375,
      '3': 80,
      '4': 100
    },
    'tiles.bl.rr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.bl.ww': {
      '1': 50.703125,
      '2': 62.328125,
      '3': 80,
      '4': 100
    },
    'tiles.suff.age': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ain': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ancy': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.suff.ar': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ard': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ory': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.s': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.sion': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.suff.sure': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.suff.tion': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ual': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.hydro': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.8125,
      '4': 128.5625
    },
    'tiles.GreekCombiningForms.tele': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.liber': {
      '1': 60.96875,
      '2': 76.75,
      '3': 87.234375,
      '4': 103.015625
    },
    'tiles.GreekCombiningForms.enne': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.GreekCombiningForms.dia': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.vert': {
      '1': 55.859375,
      '2': 69.5625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.oma': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.sight.p': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ice': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.age': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ite': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ine': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.am': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.wr': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.rh': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.que': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.acs.qué': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gh': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.olk': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.bt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gu': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.lf': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.mn': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.in': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.con': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ob': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sub': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ad': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ary': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.act': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.empt': {
      '1': 66.09375,
      '2': 83.9375,
      '3': 95.8125,
      '4': 113.65625
    },
    'tiles.roots.low': {
      '1': 52.75,
      '2': 65.203125,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pose': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.roots.sist': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ure': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.kinetic': {
      '1': 80.453125,
      '2': 104.125,
      '3': 119.859375,
      '4': 143.53125
    },
    'tiles.GreekCombiningForms.therm': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.828125,
      '4': 128.578125
    },
    'tiles.roots.equi': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.GreekCombiningForms.deca': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.GreekCombiningForms.gram': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.roots.vise': {
      '1': 57.921875,
      '2': 72.453125,
      '3': 82.125,
      '4': 100
    },
    'tiles.GreekCombiningForms.osteo': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.sight.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ace': {
      '1': 52.796875,
      '2': 65.25,
      '3': 80,
      '4': 100
    },
    'tiles.gs.alt': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ate': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.an': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.gs.ile': {
      '1': 42.515625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gn': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.gue': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.slc.mb': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.slc.ps': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.kn': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.aigh': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.avs.ough': {
      '1': 67.09375,
      '2': 85.34375,
      '3': 97.484375,
      '4': 115.734375
    },
    'tiles.avs.qué': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.slc.sc': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.slc.pn': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.im': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.com': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.LatinChameleonPrefixes.oc': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.suc': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ac': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ate': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.ceed': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.roots.fect': {
      '1': 54.8125,
      '2': 68.109375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.mit': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.press': {
      '1': 71.234375,
      '2': 91.171875,
      '3': 104.4375,
      '4': 124.375
    },
    'tiles.roots.solve': {
      '1': 69.1875,
      '2': 88.28125,
      '3': 101,
      '4': 120.09375
    },
    'tiles.suff.ward': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.GreekCombiningForms.macro': {
      '1': 77.390625,
      '2': 99.8125,
      '3': 114.71875,
      '4': 137.140625
    },
    'tiles.roots.cid': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.equ': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.hecto': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.roots.cis': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.vict': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.path': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.sight.n': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.stle': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.avs.tu': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.avs.wa': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.ng': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.nk': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.acs.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.lightyellow': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.orange': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.peach': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.pink': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.purple': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.red': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.yellow': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.lightgreen': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.cream': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.blank.clear': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.il': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.col': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.of': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.suf': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.af': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ation': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.roots.cel': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.fer': {
      '1': 45.59375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.nul': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pulse': {
      '1': 70.203125,
      '2': 89.703125,
      '3': 102.6875,
      '4': 122.203125
    },
    'tiles.roots.struct': {
      '1': 73.265625,
      '2': 94.015625,
      '3': 107.828125,
      '4': 128.578125
    },
    'tiles.suff.xion': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.GreekCombiningForms.meter': {
      '1': 72.265625,
      '2': 92.609375,
      '3': 106.140625,
      '4': 126.484375
    },
    'tiles.roots.civ': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.judg': {
      '1': 60.9375,
      '2': 76.703125,
      '3': 87.203125,
      '4': 102.953125
    },
    'tiles.GreekCombiningForms.kilo': {
      '1': 53.796875,
      '2': 66.65625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.cit': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.spect': {
      '1': 70.203125,
      '2': 89.71875,
      '3': 102.703125,
      '4': 122.21875
    },
    'tiles.GreekCombiningForms.psycho': {
      '1': 86.59375,
      '2': 112.75,
      '3': 130.15625,
      '4': 156.3125
    },
    'tiles.sight.m': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ct': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lb': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lch': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.ld': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lf': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lk': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lm': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lp': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.lth': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.mp': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.mpt': {
      '1': 55.828125,
      '2': 69.515625,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nch': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nd': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ir': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.cor': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.op': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sug': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ag': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ative': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.roots.cept': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.roots.form': {
      '1': 63,
      '2': 79.609375,
      '3': 90.65625,
      '4': 107.25
    },
    'tiles.roots.pel': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.quaint': {
      '1': 77.34375,
      '2': 99.75,
      '3': 114.65625,
      '4': 137.0625
    },
    'tiles.roots.tract': {
      '1': 62,
      '2': 78.1875,
      '3': 88.96875,
      '4': 105.15625
    },
    'tiles.roots.annu': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.roots.poli': {
      '1': 54.796875,
      '2': 68.078125,
      '3': 80,
      '4': 100
    },
    'tiles.roots.civil': {
      '1': 57.90625,
      '2': 72.4375,
      '3': 82.109375,
      '4': 100
    },
    'tiles.roots.egal': {
      '1': 58.921875,
      '2': 73.875,
      '3': 83.8125,
      '4': 100
    },
    'tiles.roots.clude': {
      '1': 70.203125,
      '2': 89.703125,
      '3': 102.6875,
      '4': 122.203125
    },
    'tiles.roots.cracy': {
      '1': 70.234375,
      '2': 89.75,
      '3': 102.75,
      '4': 122.265625
    },
    'tiles.roots.spire': {
      '1': 66.109375,
      '2': 83.953125,
      '3': 95.84375,
      '4': 113.6875
    },
    'tiles.GreekCombiningForms.therapy': {
      '1': 88.65625,
      '2': 115.640625,
      '3': 133.59375,
      '4': 160.578125
    },
    'tiles.sight.l': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nk': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.nt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.pt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sc': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sk': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sm': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.sp': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.endblend.xt': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.bl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.br': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.chl': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.chr': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.cl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.cr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.dr': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.dw': {
      '1': 47.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sup': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.cracy': {
      '1': 70.234375,
      '2': 89.75,
      '3': 102.75,
      '4': 122.265625
    },
    'tiles.roots.cess': {
      '1': 63.046875,
      '2': 79.671875,
      '3': 90.71875,
      '4': 107.34375
    },
    'tiles.roots.fuse': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.roots.pend': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.roots.quit': {
      '1': 55.8125,
      '2': 69.5,
      '3': 80,
      '4': 100
    },
    'tiles.roots.verse': {
      '1': 70.234375,
      '2': 89.75,
      '3': 102.75,
      '4': 122.265625
    },
    'tiles.roots.de': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.demo': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.GreekCombiningForms.crat': {
      '1': 55.859375,
      '2': 69.5625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.jucic': {
      '1': 64.046875,
      '2': 81.078125,
      '3': 92.40625,
      '4': 109.421875
    },
    'tiles.GreekCombiningForms.cracy': {
      '1': 70.234375,
      '2': 89.75,
      '3': 102.75,
      '4': 122.265625
    },
    'tiles.roots.crat': {
      '1': 55.859375,
      '2': 69.5625,
      '3': 80,
      '4': 100
    },
    'tiles.roots.the': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    "tiles.sight.'": {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.k': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sm': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.skr': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sk': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.shr': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.scr': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.scl': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sch': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sc': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.pr': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.pl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.phr': {
      '1': 51.734375,
      '2': 63.765625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.phl': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gw': {
      '1': 47.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gr': {
      '1': 40.453125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.gl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.fr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.fl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.sus': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.an': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.cy': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.count': {
      '1': 72.21875,
      '2': 92.546875,
      '3': 106.078125,
      '4': 126.40625
    },
    'tiles.roots.gest': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.roots.pense': {
      '1': 75.328125,
      '2': 96.921875,
      '3': 111.28125,
      '4': 132.875
    },
    'tiles.roots.range': {
      '1': 72.25,
      '2': 92.59375,
      '3': 106.125,
      '4': 126.46875
    },
    'tiles.suff.ics': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.dem': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.GreekCombiningForms.phil': {
      '1': 54.796875,
      '2': 68.078125,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.anthro': {
      '1': 79.40625,
      '2': 102.640625,
      '3': 118.109375,
      '4': 141.34375
    },
    'tiles.roots.popul': {
      '1': 72.21875,
      '2': 92.546875,
      '3': 106.0625,
      '4': 126.390625
    },
    'tiles.GreekCombiningForms.ethno': {
      '1': 72.21875,
      '2': 92.546875,
      '3': 106.078125,
      '4': 126.40625
    },
    'tiles.GreekCombiningForms.soph': {
      '1': 66.078125,
      '2': 83.921875,
      '3': 95.796875,
      '4': 113.640625
    },
    'tiles.GreekCombiningForms.astr': {
      '1': 55.859375,
      '2': 69.5625,
      '3': 80,
      '4': 100
    },
    'tiles.sight.-': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.j': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sn': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sp': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sph': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.spl': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.spr': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.squ': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.st': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.str': {
      '1': 45.59375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.sw': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.thr': {
      '1': 46.609375,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.thw': {
      '1': 53.765625,
      '2': 66.640625,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.tr': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.initblend.tw': {
      '1': 42.5,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.ble': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.cle': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ex': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.dis': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.dle': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.fle': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ap': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.dict': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.cur': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.gress': {
      '1': 71.234375,
      '2': 91.171875,
      '3': 104.4375,
      '4': 124.375
    },
    'tiles.roots.ply': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.rect': {
      '1': 55.859375,
      '2': 69.5625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ily': {
      '1': 42.515625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pl': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.arch': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.GreekCombiningForms.polis': {
      '1': 65.0625,
      '2': 82.484375,
      '3': 94.09375,
      '4': 111.515625
    },
    'tiles.roots.polis': {
      '1': 65.0625,
      '2': 82.484375,
      '3': 94.09375,
      '4': 111.515625
    },
    'tiles.GreekCombiningForms.theo': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.roots.cred': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.GreekCombiningForms.hydr': {
      '1': 61.984375,
      '2': 78.171875,
      '3': 88.953125,
      '4': 105.140625
    },
    'tiles.sight.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.i': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.arrowleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.arrowright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.bracketleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.bracketright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.colon': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.comma': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.exclamation': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.plus': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.question': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.zle': {
      '1': 46.625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.tle': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.qule': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.consle.ple': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.nle': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.mle': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ef': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.dif': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.kle': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.consle.gle': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.ar': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.dom': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.roots.dict': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.ject': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.point': {
      '1': 67.09375,
      '2': 85.34375,
      '3': 97.484375,
      '4': 115.734375
    },
    'tiles.roots.rupt': {
      '1': 57.875,
      '2': 72.390625,
      '3': 82.046875,
      '4': 100
    },
    'tiles.suff.ine': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.arch': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.roots.grad': {
      '1': 61.984375,
      '2': 78.171875,
      '3': 88.953125,
      '4': 105.140625
    },
    'tiles.roots.fid': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.fidel': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.roots.flect': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.roots.flict': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.cardi': {
      '1': 66.109375,
      '2': 83.953125,
      '3': 95.84375,
      '4': 113.6875
    },
    'tiles.sight.b': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.h': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym._': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym.-': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.apostrophe': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.arrowleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.arrowright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.bracketleft': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.bracketright': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.colon': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.comma': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.dash': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.exclamation': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.period': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.plus': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.question': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sym2.schwa': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.a': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.at': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.LatinChameleonPrefixes.as': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ee': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.duct': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.roots.just': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.port': {
      '1': 57.875,
      '2': 72.390625,
      '3': 82.046875,
      '4': 100
    },
    'tiles.roots.sent': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.suff.ion': {
      '1': 49.671875,
      '2': 60.875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.demo': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.roots.dic': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.pact': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.roots.fess': {
      '1': 58.9375,
      '2': 73.890625,
      '3': 83.828125,
      '4': 100
    },
    'tiles.roots.scribe': {
      '1': 76.359375,
      '2': 98.375,
      '3': 113.015625,
      '4': 135.03125
    },
    'tiles.roots.tent': {
      '1': 55.828125,
      '2': 69.515625,
      '3': 80,
      '4': 100
    },
    'tiles.GreekCombiningForms.gastr': {
      '1': 67.125,
      '2': 85.390625,
      '3': 97.546875,
      '4': 115.8125
    },
    'tiles.sight.c': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.g': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ab': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ac': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.act': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ad': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.af': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ag': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.al': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.an': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ap': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ar': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.as': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.at': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.be': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.circum': {
      '1': 82.515625,
      '2': 107,
      '3': 123.3125,
      '4': 147.796875
    },
    'tiles.pref.il': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.o': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.oc': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.of': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.per': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.se': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ency': {
      '1': 64.0625,
      '2': 81.09375,
      '3': 92.421875,
      '4': 109.4375
    },
    'tiles.suff.ern': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ese': {
      '1': 52.796875,
      '2': 65.25,
      '3': 80,
      '4': 100
    },
    'tiles.suff.fic': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ian': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ious': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.5,
      '4': 100.859375
    },
    'tiles.roots.derv': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.roots.divin': {
      '1': 65.0625,
      '2': 82.484375,
      '3': 94.09375,
      '4': 111.515625
    },
    'tiles.roots.quire': {
      '1': 67.109375,
      '2': 85.375,
      '3': 97.53125,
      '4': 115.796875
    },
    'tiles.roots.pute': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.roots.sect': {
      '1': 58.9375,
      '2': 73.890625,
      '3': 83.828125,
      '4': 100
    },
    'tiles.roots.tend': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.GreekCombiningForms.poli': {
      '1': 54.796875,
      '2': 68.078125,
      '3': 80,
      '4': 100
    },
    'tiles.sight.d': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.sight.f': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.col': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.com': {
      '1': 59.9375,
      '2': 75.296875,
      '3': 85.515625,
      '4': 100.875
    },
    'tiles.pref.con': {
      '1': 54.8125,
      '2': 68.09375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.contra': {
      '1': 78.390625,
      '2': 101.21875,
      '3': 116.40625,
      '4': 139.234375
    },
    'tiles.pref.cor': {
      '1': 50.71875,
      '2': 62.34375,
      '3': 80,
      '4': 100
    },
    'tiles.pref.di': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.dif': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ef': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.em': {
      '1': 48.671875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.en': {
      '1': 43.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.ex': {
      '1': 42.53125,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.extra': {
      '1': 66.109375,
      '2': 83.96875,
      '3': 95.859375,
      '4': 113.71875
    },
    'tiles.pref.fore': {
      '1': 56.859375,
      '2': 70.96875,
      '3': 80.359375,
      '4': 100
    },
    'tiles.pref.intro': {
      '1': 63,
      '2': 79.59375,
      '3': 90.640625,
      '4': 107.234375
    },
    'tiles.pref.ob': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.octo': {
      '1': 60.953125,
      '2': 76.71875,
      '3': 87.21875,
      '4': 102.984375
    },
    'tiles.pref.op': {
      '1': 44.546875,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.pref.pro': {
      '1': 51.734375,
      '2': 63.765625,
      '3': 80,
      '4': 100
    },
    'tiles.pref.self': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ent': {
      '1': 49.6875,
      '2': 60.890625,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ery': {
      '1': 49.703125,
      '2': 60.921875,
      '3': 80,
      '4': 100
    },
    'tiles.suff.et': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ial': {
      '1': 42.515625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ice': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.suff.ism': {
      '1': 53.796875,
      '2': 66.671875,
      '3': 80,
      '4': 100
    },
    'tiles.roots.archy': {
      '1': 71.234375,
      '2': 91.171875,
      '3': 104.4375,
      '4': 124.375
    },
    'tiles.roots.jec': {
      '1': 47.65625,
      '2': 60,
      '3': 80,
      '4': 100
    },
    'tiles.roots.mand': {
      '1': 71.21875,
      '2': 91.140625,
      '3': 104.390625,
      '4': 124.3125
    },
    'tiles.roots.pres': {
      '1': 60.984375,
      '2': 76.765625,
      '3': 87.25,
      '4': 103.03125
    },
    'tiles.roots.tact': {
      '1': 54.8125,
      '2': 68.109375,
      '3': 80,
      '4': 100
    },
    'tiles.roots.secut': {
      '1': 70.203125,
      '2': 89.71875,
      '3': 102.703125,
      '4': 122.21875
    },
    'tiles.GreekCombiningForms.psych': {
      '1': 75.328125,
      '2': 96.921875,
      '3': 111.28125,
      '4': 132.875
    },
    'tiles.GreekCombiningForms.scop': {
      '1': 65.0625,
      '2': 82.5,
      '3': 94.109375,
      '4': 111.546875
    },
    'tiles.sight.e': {
      '1': 40,
      '2': 60,
      '3': 80,
      '4': 100
    }
  };

  formatAmount (amount: number) {
    if (amount === 0) {
      return 'Free';
    }

    const amountArr = amount.toString().split('');
    amountArr.splice(-2, 0, '.');
    return amountArr.join('');
  }

  getTileClasses (col: string, userSettings: any) {
    if (col.indexOf('.') === -1) {
      return '';
    }

    let tileType = col.substring(col.indexOf('.') + 1, col.lastIndexOf('.'));
    tileType = tileType === 'sight.' ? 'sight' : tileType;
    let classes = '';
    if (tileType === 'blank') {
      const tile = col.substring(col.lastIndexOf('.') + 1);
      if (tile === '1') {
        classes = 'c-lightblue';
      } else if (tile === '2') {
        classes = 'c-clear';
      } else if (tile === '3') {
        classes = 'c-yellow';
      } else if (tile === '1') {
        classes = 'c-lightblue';
      } else {
        classes = `c-${tile}`;
      }
    } else {
      if (userSettings.tiles[tileType]) {
        classes = `${userSettings.tiles[tileType].tilebgcolor} ${userSettings.tiles[tileType].tilefontcolor}`;
      }
    }
    return classes;
  }

  convertSymbolTiles (symbolName: string, category: string) {
    if (!symbolName) {
      return '';
    }
    switch (symbolName.toLowerCase()) {
      case 'addition':
        return '+';
      case 'subtraction':
        return '-';
      case 'multiplication':
        return '*';
      case  'division':
        return '÷';
      case 'equals':
        return '=';
      case 'fraction':
        return '/';
      case 'colon':
        return ':';
      case 'comma':
        return ',';
      case 'exclamation':
        return '!';
      case 'question':
        return '?';
      case 'schwa':
        return 'ə';
      case 'plus':
        return '+';
      case 'arrowleft':
        return '<';
      case 'arrowright':
        return '>';
      case 'bracketleft':
        return '[';
      case 'bracketright':
        return ']';
      case '4':
      case '1':
      case '3':
        return category === 'num' ? symbolName : '';

      case 'period':
        return '.';
      case 'apostrophe':
        return "'";
      case 'dash':
        return '-';
      case 'blank':
      case 'lightblue':
      case 'yellow':
        return '';
      default:
        return symbolName;
    }
  }

  getLessonPlanStepUrl (
    activityName: string,
    courseWorkbookId: string,
    lessonPlanId: string,
    lessonPlanStepId
  ) {
    const route = `${courseWorkbookId}/${lessonPlanId}/${lessonPlanStepId}`;

    // TODO: This needs to change
    if (activityName.toLowerCase() === 'blackboard' || activityName.toLowerCase() === 'tileboard') {
      return `activity/blackboard/${route}`;
    }

    if (activityName.toLowerCase() === 'workbook tiles') {
      return `activity/workbook-tiles/${route}`;
    }

    if (activityName.toLowerCase().indexOf('mini tile') >= 0) {
      return `activity/mini-tiles/${route}`;
    }

    if (activityName.toLowerCase() === 'timed reading') {
      return `activity/timed-reading/${route}`;
    }

    if (activityName.toLowerCase() === 'lettercards') {
      return `activity/lettercards/${route}`;
    }

    if (activityName.toLowerCase() === 'wordcards') {
      return `activity/wordcards/${route}`;
    }

    if (activityName.toLowerCase() === 'sentence parts') {
      return `activity/phrases/${route}`;
    }

    if (activityName.toLowerCase() === 'sentences') {
      return `activity/sentences/${route}`;
    }

    if (activityName.toLowerCase() === 'passages') {
      return `activity/passages/${route}`;
    }

    if (activityName.toLowerCase() === 'pdf viewer') {
      return `activity/pdf-viewer/${route}`;
    }

    if (activityName.toLowerCase() === 'whiteboard') {
      return `activity/whiteboard/${route}`;
    }

    if (activityName.toLowerCase() === 'spelling') {
      return `activity/spelling/${route}`;
    }

    if (activityName.toLowerCase() === 'blank tiles') {
      return `activity/blank-tiles/${route}`;
    }

    if (activityName.toLowerCase() === 'flashcards') {
      return `activity/flashcards/${route}`;
    }

    return '/';
  }

  sortDroppedTiles (tiles: string[]): Observable<any> {
    return new Observable(observer => {
      this.tilesService
        .getAllTiles()
        .pipe(take(1))
        .subscribe((tileBank: any) => {
          const tiles1 = [...(tiles || [])];
          const sortedTiles = tiles1.sort((a: any, b: any) => {
            a = a.tile || a;
            b = b.tile || b;
            const tileAType = a.substring(
              a.indexOf('.') + 1,
              a.lastIndexOf('.')
            );
            const tileBType = b.substring(
              b.indexOf('.') + 1,
              b.lastIndexOf('.')
            );

            if (tileAType === tileBType) {
              if (a > b) {
                return 1;
              }

              if (a < b) {
                return -1;
              }

              return 0;
            } else {
              const tileTypeAIndex = tileBank.findIndex(
                type => type.key === tileAType
              );
              const tileTypeBIndex = tileBank.findIndex(
                type => type.key === tileBType
              );

              if (tileTypeAIndex > tileTypeBIndex) {
                return 1;
              }

              if (tileTypeAIndex < tileTypeBIndex) {
                return -1;
              }

              return 0;
            }
          });
          observer.next(sortedTiles);
        });
    });
  }

  randomString (length) {
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+';
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  mobileCheck () {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  isCourseWorkbookActive (courseWorkbook: any) {
    if (
      !courseWorkbook.fullWords?.length &&
      !courseWorkbook.files?.length &&
      !courseWorkbook.passages?.length &&
      !courseWorkbook.phrases?.length &&
      !courseWorkbook.sentences?.length
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkActivityTab(activityTypeId): 'both' | 'onlyWords' | 'onlyTiles' {
    if (activityTypeId === 'blackboard') {
      return 'onlyWords';
    }
    return 'both';
  }

  setActiveLessonStepTab(tab: 'Words' | 'Tiles') {
    this.activeLessonStepTab = tab;
  }


  openVerificationEmailDialog() {
    const message = "Welcome to Whizzimo! We just sent an email to you. Click the link in the email to verify your account so that you can get started using Whizzimo's activities. Please read this <a target='_blank' href=\"https://intercom.help/whizzimo/en/articles/10276275-email-verification\">support article</a> if you need any assistance verifying your email address<br /><br />Happy Teaching!";
    const confirmFunction = this.sendVerificationEmail.bind(this);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '300px',
      width: '400px',
      panelClass: 'custom-confirm-dialog',
      disableClose: true,
      data: {
        title: 'Account Verification Email Sent',
        logOffNoClick: true,
        message,
        confirmLabel: 'RESEND EMAIL',
        cancelLabel: 'OKAY',
        confirmFunction
      },
    });
  }

  sendVerificationEmail() {
    const message = "We just resent the verification email to you. Click the link in the email to verify your account so that you can get started using Whizzimo's activities. Please read this <a target='_blank' href=\"https://whizzimo.zendesk.com/hc/en-us/articles/21742263635987\">support article</a> if you need any assistance verifying your email address.<br /><br />Happy Teaching!";
    this.accountService.resendEmailVerification(JSON.parse(localStorage.profile).user_id)
      .pipe(take(1))
      .subscribe(() => {
          const dialogRef = this.dialog.open(MessageDialogComponent, {
            panelClass: 'custom-confirm-dialog',
            disableClose: true,
            data: {
              title: 'Verification Email Sent',
              content: message,
              logOff: true
            }
          });
        },
        (error) => {
          const dialogRef = this.dialog.open(MessageDialogComponent, {
            panelClass: 'custom-confirm-dialog',
            data: {
              title: 'Error',
              content: 'There was an error sending the email.  Please try again. If the problem persists, please contact support.'
            }
          });
        });
  }

  getTermsandPrivacy(func: any) {
    combineLatest([this.termsOfServiceService.get(), this.privacyPolicyService.get()])
        .pipe(take(1))
        .subscribe(([termsOfService, privacyPolicy]) => {
          UtilityService.termsOfService = termsOfService;
          UtilityService.privacyPolicy = privacyPolicy;

          func();
        });
  }

  openTermsOfServiceDialogHelper() {
    if (!UtilityService.privacyPolicy || !UtilityService.termsOfService) { 
      this.getTermsandPrivacy(this.openTermsOfServiceDialog.bind(this));
    } else {
      this.openTermsOfServiceDialog();
    }
  }

    openTermsOfServiceDialog() {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '300px',
        width: '600px',
        panelClass: 'custom-confirm-dialog',
        disableClose: true,
        data: {
          title: 'Terms of Service and Privacy Policy',
          message: UtilityService.termsOfService.value + UtilityService.privacyPolicy.value,
          logOffNoClick: true,
          confirmLabel: 'ACCEPT & CONTINUE',
          cancelLabel: 'DECLINE & LOG OUT',
          confirmFunction: this.agreeToTermsOfService.bind(this)
        }
      });
      
      // get terms of service and privacy policy using rxjs
      

    }

    agreeToTermsOfService() {
      this.userService.setTermsOfService(JSON.parse(localStorage.profile).user_metadata.uid, JSON.parse(localStorage.profile).user_id)
        .pipe(take(1))
        .subscribe(() => {
          let profile = JSON.parse(localStorage.profile);
          profile.user_metadata.tosAccepted = true;
          localStorage.setItem('profile', JSON.stringify(profile));
          window.location.reload();
        });

        // this.accountService.acceptTermsOfService(JSON.parse(localStorage.profile).user_id, this.termsOfService._id)
        //   .pipe(take(1))
        //   .subscribe(() => {
        //     let profile = JSON.parse(localStorage.profile);
        //     profile.user_metadata.tosAccepted = true;
        //     localStorage.setItem('profile', JSON.stringify(profile));
        //     window.location.reload();
        //   });
    }

    // Generate a random hex color
    getRandomColor(): string {
      const colors: string[] = [
        '#E4A9FF'
      ];
  
      const randomIndex: number = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
  }

    // TODO: It may be better to use the activityId instead of the activityName
    isAllowedStep(activityName: any, plan): boolean {
      if (!activityName) return false;
  
      const selectedPlan = plan.toLowerCase();

      // Define individual plan variables
      const isDemo = selectedPlan === 'demo';
      const isDemoV2 = selectedPlan === 'demo v2';
      const isHero = selectedPlan === 'hero';
      const isHeroV2 = selectedPlan === 'hero v2';
      const isSuperhero = selectedPlan === 'superhero';
      const isSuperheroV2 = selectedPlan === 'superhero v2';
      const isSuperheroPlus = selectedPlan === 'superhero plus';
      const isSuperheroPlusV2 = selectedPlan === 'superhero plus v2';
  
      // if(isSuperheroPlus || isSuperheroPlusV2) {
      //   return true;
      // }
  
      // Other conditions
      // const isPhonetic = workbook?.workbook?.filters?.isPhonetic;
      // const hasNonsense = workbook?.workbook?.filters?.isNonsense?.includes('nonsense');
      // const hasPhrases = workbook?.phrases?.length > 0 && workbook.phrases[0]?.numPhrase > 0;
      // const hasSentences = workbook?.sentences?.length > 0 && workbook.sentences[0]?.numSentence > 0;
      // const hasPassages = workbook?.passages?.length > 0 && workbook.passages[0]?.numPassage > 0;
      // const hasFiles = workbook?.files?.length > 0 && workbook.files[0]?.numFile > 0;
  
      switch (activityName) {
        case 'Blackboard':
        case 'Tileboard':
          return true;
        case 'Flashcards':
          return isDemo || isHero || isHeroV2 || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Lettercards':
          return isHero || isHeroV2 || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Whiteboard':
          return isHeroV2 || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Workbook Tiles':
          return isHero || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Mini Tile Board':
        case 'Mini Tiles':
          return isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Wordcards':
          return isHero || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Blank Tiles':
          return isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Spelling':
          return isHero || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Timed Reading':
          return isHero || isSuperhero || isSuperheroV2 || isSuperheroPlus || isSuperheroPlusV2;
        case 'Sentence Parts':
          return isSuperhero || isSuperheroPlus || isSuperheroPlusV2;
        case 'Sentences':
          return isSuperhero || isSuperheroPlus || isSuperheroPlusV2;
        case 'Passages':
          return isSuperhero || isSuperheroPlus || isSuperheroPlusV2;
        case 'PDF Viewer':
          return isSuperhero || isSuperheroPlus || isSuperheroPlusV2;
        default:
          return false;
      }
    }

  isSafari(): boolean {
    const userAgent = window.navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    return isSafari;
  }
  
}
