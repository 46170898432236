import { AmplitudeService } from '../../services/amplitude.service';
import * as accountActions from './account.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    subscriptions: any;
    card: any;
    account: any;
    promo: any;

}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    subscriptions: null,
    card: null,
    account: null,
    promo: null
};

export function reducer(state = initialState, action: accountActions.AccountAction): State {
    switch (action.type) {
        case accountActions.AccountActionTypes.GetAllSubscriptions: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.GetAllSubscriptionsSuccess: {
            return {
                ...state,
                subscriptions: action.payload,
                error: null
            };
        }
        case accountActions.AccountActionTypes.GetAllSubscriptionsFail: {
            return {
                ...state,
                subscriptions: null,
                error: action.payload
            };
        }
        case accountActions.AccountActionTypes.ResendEmailVerification: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.ResendEmailVerificationSuccess: {
            return {
                ...state,
                error: null
            };
        }
        case accountActions.AccountActionTypes.ResendEmailVerificationFail: {
          return {
            ...state,
            error: action.payload
          };
        }
        case accountActions.AccountActionTypes.AddCreditCard: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.AddCreditCardSuccess: {
            let account = JSON.parse(JSON.stringify(state.account));
            account.cards.data[0] = action.payload;
            return {
                ...state,
                // card: action.payload,
                account,
                error: null
            };
        }
        case accountActions.AccountActionTypes.AddCreditCardFail: {
            return {
                ...state,
                card: null,
                error: action.payload
            };
        }


        case accountActions.AccountActionTypes.DeleteCreditCard: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.DeleteCreditCardSuccess: {
            let account = JSON.parse(JSON.stringify(state.account));
            delete account.cards.data[0];
            return {
                ...state,
                account,
                error: null
            };
        }
        case accountActions.AccountActionTypes.DeleteCreditCardFail: {
            return {
                ...state,
                error: action.payload
            };
        }

        case accountActions.AccountActionTypes.DeleteCreditCard: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        // case accountActions.AccountActionTypes.DeleteCreditCardSuccess: {
        //     return {
        //         ...state,
        //         card: null,
        //         error: null
        //     };
        // }
        // case accountActions.AccountActionTypes.DeleteCreditCardFail: {
        //     return {
        //         ...state,
        //         error: action.payload
        //     };
        // }

        // case accountActions.AccountActionTypes.GetPaymentAccount: {
        //     return {
        //         ...state,
        //         pending: true,
        //         succeeded: false,
        //         error: null
        //     };
        // }
        case accountActions.AccountActionTypes.GetPaymentAccountSuccess: {
            let stripe = action.payload;
            let discount = stripe?.discount;

            if(discount) {
                const coupon = discount?.coupon;
                let discountString = '';

                if(coupon) {

                    if(coupon?.percent_off) {
                        discountString = coupon.percent_off + '% off ' + coupon.duration;
                    } else {    
                        discountString = '$' + (coupon.amount_off / 100).toFixed(2) + ' off ' + coupon.duration;
                    }

                    AmplitudeService.identifyUser({
                        discount: discountString
                    });
        
                    (<any>window).Intercom("update", {
                        discount: discountString
                    });
        
                }
            }

            if(stripe?.balance < 0 || stripe?.account_balance < 0) {
                // {{(this.account$ | async).balance < 0 ? '$' + (((this.account$ | async).balance / 100) * -1).toFixed(2) : '$' + (((this.account$ | async).account_balance / 100) * -1).toFixed(2)}}
                let balance = stripe.balance < 0 ? '$' + ((stripe.balance / 100) * -1).toFixed(2) : '$' + ((stripe.account_balance / 100) * -1).toFixed(2);

                AmplitudeService.identifyUser({
                    credit: balance
                });
    
                (<any>window).Intercom("update", {
                    credit: balance
                });
            }

            return {
                ...state,
                account: action.payload,
                error: null
            };
        }
        case accountActions.AccountActionTypes.GetPaymentAccountFail: {
            return {
                ...state,
                account: null,
                error: action.payload
            };
        }
        case accountActions.AccountActionTypes.GetPromoCode: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.GetPromoCodeSuccess: {
            return {
                ...state,
                promo: action.payload,
                error: null
            };
        }
        case accountActions.AccountActionTypes.GetPromoCodeFail: {
            return {
                ...state,
                promo: null,
                error: action.payload
            };
        }

        case accountActions.AccountActionTypes.CancelSubscription: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.CancelSubscriptionSuccess: {
            let account = JSON.parse(JSON.stringify(state.account));
            // delete account.subscriptions.data[0];
            delete account.nextInvoice;
            return {
                ...state,
                account,
                error: null
            };
        }

        case accountActions.AccountActionTypes.CancelRenewal: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.CancelRenewalSuccess: {
            let account = JSON.parse(JSON.stringify(state.account));
            const index = account.subscriptions.data.findIndex(item => item.id === action.payload.id);
            account.subscriptions.data[index] = action.payload;
            return {
                ...state,
                account,
                error: null
            };
        }

        case accountActions.AccountActionTypes.ScheduleNewPlan: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.ScheduleNewPlanSuccess: {
            let subscriptions = JSON.parse(JSON.stringify(state.subscriptions));
            subscriptions.data.push(action.payload);
            return {
                ...state,
                subscriptions,
                error: null
            };
        }

        case accountActions.AccountActionTypes.CancelScheduledSubscription: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case accountActions.AccountActionTypes.CancelScheduledSubscriptionSuccess: {
            let subscriptions = JSON.parse(JSON.stringify(state.subscriptions));
            const index = subscriptions.data.findIndex(subscription => subscription.id === action.payload);
            if (index > -1) {
                subscriptions.data.splice(index, 1);
            }
            return {
                ...state,
                subscriptions,
                error: null
            };
        }


        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
// export const getDummyData = (state: State) => state.dummyData;
export const getPromo = (state: State) => state.promo;
export const getPaymentAccount = (state: State) => state.account;
export const getCardInfo = (state: State) => state.card;
export const getAllSubscriptions = (state: State) => state.subscriptions;
