import { Store } from '@ngrx/store';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CourseService } from '../../services/courses.service';
import { combineLatest, forkJoin, Subject, Subscription } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { CourseWorkbooksService } from '../../services/course-workbooks.service';
import { ActivityTypeService } from '../../services/activity-type.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { WorkbookMenuComponent } from './workbooks.component';
import { faEdit, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from '../../services/activity.service';
import { ListenerService } from '../../services/listener.service';
import {WorkbooksService} from '../../services/workbooks.service';
import { getWorkbook, getSelectedWorkbook, selectActivityTypes, selectCurrentTheme, selectCourseWorkbookState, getCourseWorkbook } from '../../store';
import { GetWorkbook } from '../../store/workbook/workbook.actions';
import { ActivityHeaderService } from '../../services/activity-header.service';
@Component({
  selector: 'app-course-workbooks-element',
  templateUrl: './html/course-workbooks.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'

  ]
})

export class CourseWorkBooksComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() courses: any[] = [];
  @Input() currentCourse: any;
  @Input() courseWorkbooks: any[] = [];
  private courseMenuOpen = false;
  @Output() toggleCourseMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteCourseWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() editWorkbook: EventEmitter<any> = new EventEmitter();
  activities: any[] = [];
  activityDialog: any;
  currentWorkbook: any;

  constructor(
    private _api: ApiService,
    private dialog: MatDialog,
    private activityTypeService: ActivityTypeService,
    private activityService: ActivityService,
    private store: Store
  ) { }

  ngOnInit() {
    this.activityTypeService
      .getAll()
      .pipe(
        take(1)
      )
      .subscribe(activities => {
        // @ts-ignore
        this.activities = activities.map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openDialog(currentCourseWorkbook): void {
    this.currentWorkbook = currentCourseWorkbook;
    // this.store.dispatch(new GetWorkbook({workbookId:  currentCourseWorkbook.workbookId || currentCourseWorkbook.workbook.workbookId}));
    this.activityDialog = this.dialog.open(CourseWorkbookMenuComponent, {
      data: {
        workbook: currentCourseWorkbook,
        activities: this.activities,
        isHomePage: false
      },
      width: '350px'
    });

    this.activityDialog.componentInstance.functions = {
      closeDialog: (activity) => this.activityDialog.close()
    };
  }

  openCourseWorkbookSettingsDialog(currentWorkbook): void {
    const dialogref = this.dialog.open(CourseWorkbookSettingsMenuComponent, {
      data: { workbook: currentWorkbook },
      width: '350px'
    });

    dialogref.componentInstance.functions = {
      deleteCourseWorkbook: (courseWorkbookId) => {
        dialogref.close();
        this.deleteCourseWorkbook.emit(courseWorkbookId);
      },
      deleteWorkbook: (workbookId) => {
        dialogref.close();
        this.deleteWorkbook.emit(workbookId);
      },
      editWorkbook: (workbookId) => {
        dialogref.close();
        this.editWorkbook.emit(workbookId);
      }
    };
  }
  openCourseSelection() {
    this.courseMenuOpen = !this.courseMenuOpen;
    this.toggleCourseMenuEvent.emit(this.courseMenuOpen);
  }
}

@Component({
  selector: 'app-course-dialog',
  templateUrl: './html/course-dialog.html'
})
export class CourseDialogComponent {
  constructor() { }
}

@Component({
  selector: 'app-course-workbook-menu',
  templateUrl: './html/course-workbook-menu.html'
})
export class CourseWorkbookMenuComponent implements OnDestroy, AfterViewInit {
  functions;
  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  selectedPlan: string;
  workbook: any;
  courseWorkbookId: any;
  activities: any;
  private unsubscribe$: Subject<void> = new Subject();
  activityType: string;
  isDarkMode: boolean;
  courseWorkbook: any;

  // New: Map to store precomputed class mappings
  activityClassMap: { [key: string]: any } = {};
  titleClassMap: { [key: string]: any } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private listener: ListenerService,
    private workbooksService: WorkbooksService,
    private store: Store,
    private elementRef: ElementRef,
    private activityHeaderService: ActivityHeaderService
  ) {
    let workbookId = data.workbookId || data.workbook.workbookId;
    const courseWorkbookId = data._id;

    // this.listener.getPlan().subscribe((res: string) => {
    //   this.selectedPlan = res.toLowerCase();
    // });

    // this.workbooksService
    //   .get(data.workbookId || data.workbook.workbookId)
    //   .subscribe((result: any) => {
    //     this.workbook = result;
    //   });
      // setTimeout(() => {
      //   this.store.select(getSelectedWorkbook)
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe((result: any) => {
      //     this.workbook = JSON.parse(JSON.stringify(result));
      //   });

      // }, 300);
      this.store.select(getCourseWorkbook, { id: courseWorkbookId})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(courseWorkbook => {
          this.courseWorkbook = courseWorkbook;
          if (this.courseWorkbook.workbook[0]) {
            this.courseWorkbook.workbook = this.courseWorkbook.workbook[0];
          }
        });

      combineLatest([
        this.store.select(getWorkbook, { id: workbookId }),
        this.listener.getPlan()
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([workbook, plan]) => {
        if (!plan) {
          return;
        }
        this.selectedPlan = plan.toLowerCase();
        this.workbook = JSON.parse(JSON.stringify(workbook));
        if (this.selectedPlan === 'staff' || this.selectedPlan === 'superhero plus' || this.selectedPlan === 'superhero plus v2') {
          this.workbook = this.data.workbook;
          if (this.workbook[0]) {
            this.workbook = this.workbook[0];
          }
        }
        
        if (!this.workbook.filters) {
          this.workbook = this.workbook.workbook;
        }
        this.updateActivityClassMap(); // Update class mappings whenever workbook or plan changes
        this.updateTitleClassMap();
      });

      this.store.select(selectActivityTypes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(activities => {
        if (!activities) {
          this.activities = [];
          return;
        }
        // @ts-ignore
        this.activities = JSON.parse(JSON.stringify(activities)).map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';

            // NOTE: Temporary fix for the name of the activity. This name comes from the backend.
            activity.name = 'Tileboard';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
        this.updateActivityClassMap(); // Update class mappings when activities change
        this.updateTitleClassMap();

        console.log(this.activityClassMap);
        console.log(this.titleClassMap);

        this.activities.sort((a, b) => a.order - b.order);
      });

      const splitedUrl = this.router.url.split('/');
      this.activityType = splitedUrl[2];

      this.store.select(selectCurrentTheme)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(theme => {
        if (theme === 'theme-dark') {
          this.isDarkMode = true;
        } else {
          this.isDarkMode = false;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    const activeEl = this.elementRef.nativeElement.querySelector(".selected");
    if (activeEl) {
      activeEl.scrollIntoView();
    }
  }

  private updateActivityClassMap() {
    if (!this.activities) {
      this.activityClassMap = {};
      return;
    }

    this.activityClassMap = this.activities.reduce((map, activity) => {
      const activityName = activity.name.toLowerCase().replace(/ /g, '_');
      const disabled = this.isActivityDisabled(activity, this.selectedPlan, this.courseWorkbook, this.workbook);

      map[activity.name] = {
        [`intercom_${activityName}_activity_list_item`]: true,
        [`intercom_disabled_${activityName}_activity_list_item`]: disabled
      };

      return map;
    }, {});
  }

  private updateTitleClassMap() {
    if (!this.activities) {
      this.titleClassMap = {};
      return;
    }

    this.titleClassMap = this.activities.reduce((map, activity) => {
      const activityName = activity.name.toLowerCase().replace(/ /g, '_');

      map[activity.name] = {
        [`intercom_${activityName}_title`]: true
      };

      return map;
    }, {});
  }

  isDisabled(activity) {
    return this.isActivityDisabled(activity, this.selectedPlan, this.courseWorkbook, this.workbook);
  }

  isActivityDisabled(
    activity: any,
    selectedPlan: string,
    courseWorkbook: any,
    workbook: any
  ): boolean {
    switch (activity.name) {
      case 'Flashcards':
        return selectedPlan === 'demo v2';
  
      case 'Lettercards':
        return (
          (!courseWorkbook.workbook?.filters?.isPhonetic &&
            !courseWorkbook.workbook[0]?.filters?.isPhonetic) ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2'
        );
  
      case 'Whiteboard':
        return (
          selectedPlan === 'hero' ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2'
        );
  
      case 'Workbook Tiles':
        return (
          (!courseWorkbook.workbook?.filters?.isPhonetic &&
            !courseWorkbook.workbook[0]?.filters?.isPhonetic) ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2'
        );
  
      case 'Wordcards':
        return (
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2'
        )

      case 'Blank Tiles':
        return (
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero' ||
          selectedPlan === 'hero v2'
        );
  
      case 'Mini Tiles':
      case 'Mini Tile Board':
        return (
          (!courseWorkbook.workbook?.filters?.isPhonetic &&
            !courseWorkbook.workbook[0]?.filters?.isPhonetic) ||
          selectedPlan === 'hero' ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2'
        );
  
      case 'Spelling':
        return (
          workbook.filters.isNonsense === undefined ||
          workbook.filters.isNonsense.includes('nonsense') ||
          workbook.filters.isNonsense.length === 0 ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2'
        );
  
      case 'Timed Reading':
        return (
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2'
        );
  
      case 'Sentence Parts':
        return (
          selectedPlan === 'hero' ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2' ||
          selectedPlan === 'superhero v2' ||
          !(
            courseWorkbook?.phrases?.length > 0 &&
            courseWorkbook.phrases[0]?.numPhrase > 0
          )
        );
  
      case 'Sentences':
        return (
          selectedPlan === 'hero' ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2' ||
          selectedPlan === 'superhero v2' ||
          !(
            courseWorkbook?.sentences?.length > 0 &&
            courseWorkbook.sentences[0]?.numSentence > 0
          )
        );
  
      case 'Passages':
        return (
          selectedPlan === 'hero' ||
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero v2' ||
          selectedPlan === 'superhero v2' ||
          !(
            courseWorkbook?.passages?.length > 0 &&
            courseWorkbook.passages[0]?.numPassage > 0
          )
        );
  
      case 'PDF Viewer':
        return (
          selectedPlan === 'demo' ||
          selectedPlan === 'demo v2' ||
          selectedPlan === 'hero' ||
          selectedPlan === 'hero v2' ||
          selectedPlan === 'superhero v2' ||
          !(
            courseWorkbook?.files?.length > 0 &&
            courseWorkbook.files[0]?.numFile > 0
          )
        );
  
      default:
        return false;
    }
  }

  closeActivityDialog(activity, data, e) {
    e.stopPropagation();
    if ((!this.workbook.filters.isPhonetic && (activity.name === 'Workbook Tiles' || activity.name === 'Lettercards' || activity.name.toLowerCase().indexOf('mini tile') >= 0)) ||
    (
      (activity.name === 'Sentences' && (this.data.sentences.length === 0 || this.data.sentences[0]?.numSentence === 0))
      || (activity.name === 'Passages' && (this.data.passages.length === 0 || this.data.passages[0]?.numPassage === 0))
      || (activity.name === 'Sentence Parts' && (this.data.phrases.length === 0 || this.data.phrases[0]?.numPhrase === 0))
      || (activity.name === 'PDF Viewer' && (this.data.files.length === 0 || this.data.files[0]?.numFile === 0))
      || (activity.name === 'Spelling'
        && (this.workbook.filters.isNonsense === undefined || this.workbook.filters.isNonsense.includes('nonsense') || this.workbook.filters.isNonsense.length === 0)))) {
      return;
    }

    if ((activity.name === 'Sentences' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2' || this.selectedPlan === 'superhero v2'))
    || (activity.name === 'Passages' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2' || this.selectedPlan === 'superhero v2'))
    || (activity.name === 'Sentence Parts' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2' || this.selectedPlan === 'superhero v2'))
    || (activity.name === 'PDF Viewer' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2' || this.selectedPlan === 'superhero v2'))
    || (activity.name === 'Mini Tile Board' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Whiteboard' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2'))
    || (activity.name === 'Blank Tiles' && (this.selectedPlan === 'demo' || this.selectedPlan === 'hero' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Workbook Tiles' && (this.selectedPlan === 'demo' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Lettercards' && (this.selectedPlan === 'demo' || this.selectedPlan === 'demo v2'))
    || (activity.name === 'Timed Reading' && (this.selectedPlan === 'demo' || this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Wordcards' && (this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Lettercards' && (this.selectedPlan === 'demo' || this.selectedPlan === 'demo v2'))
    || (activity.name === 'Spelling' && (this.selectedPlan === 'demo v2' || this.selectedPlan === 'hero v2'))
    || (activity.name === 'Flashcards' && (this.selectedPlan === 'demo v2'))) {
      return;
    }
    let courseWorkbookId;
    courseWorkbookId = this.data._id;

    const routeUrl = activity.url + courseWorkbookId;
    this.activityHeaderService.killEvents(null, true);
    this.activityHeaderService.drawablesSubject.next([]);
    this.router.navigate([routeUrl]);
    this.functions.closeDialog(activity);
  }
  onCancel() {
    this.functions.closeDialog();
  }
  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }

  checkSelectedItem(activity) {
    return activity.url.includes(this.activityType);
  }
}

@Component({
  selector: 'app-course-workbook-settings-menu',
  templateUrl: './html/course-workbook-settings-menu.html'
})
export class CourseWorkbookSettingsMenuComponent {
  public faEdit = faEdit;
  public faTrash = faTrash;
  public faCopy = faCopy;
  functions;
  @Output() deleteCourseWorkbook: EventEmitter<any> = new EventEmitter();
  @Output() deleteWorkbook: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  deleteCourseWorkbookEvent(courseWorkbookId) {
    return this.functions.deleteCourseWorkbook(courseWorkbookId);
  }

  deleteWorkbookEvent(workbookId) {
    return this.functions.deleteWorkbook(workbookId);
  }

  editWorkbookEvent(workbookId) {
    return this.functions.editWorkbook(workbookId);
  }
}
